<template>
  <div id="page1">
    <p class="p-a">工具下载</p>

    <div style="margin-left: 5px; margin-bottom: 10px; align-items: center;">
      <img
        src="../assets/media/tux.png"
        style="width: 32px; height 32px;"
      >
      <span style="margin-left: 5px;">Linux&nbsp;相关软件下载</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury-2.7-Fedora.x86_64.rpm</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/B7RYZmUB/tools/Linux/hawkfury-2.7-Fedora.x86_64.rpm">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/B7RYZmUB/tools/Linux/hawkfury-2.7-Fedora.x86_64.rpm')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury-2.7-openSUSE.x86_64.rpm</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/WXk2xwQH/tools/Linux/hawkfury-2.7-openSUSE.x86_64.rpm">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/WXk2xwQH/tools/Linux/hawkfury-2.7-openSUSE.x86_64.rpm')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_amd64.deb</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/dwR7tHw8/tools/Linux/hawkfury_2.7_amd64.deb">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/dwR7tHw8/tools/Linux/hawkfury_2.7_amd64.deb')">显示下载二维码</span>
    </div>

    <div style="margin-left: 5px; margin-top: 25px; margin-bottom: 10px; align-items: center;">
      <img
        src="../assets/media/openwrt.png"
        style="width: 32px; height 32px;"
      >
      <span style="margin-left: 5px;">Openwrt&nbsp;相关软件下载</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_x86_64_For-OpenWrt-ge-22.03.0.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/99t-7jOy/tools/OpenWrt/hawkfury_2.7_x86_64_For-OpenWrt-ge-22.03.0.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/99t-7jOy/tools/OpenWrt/hawkfury_2.7_x86_64_For-OpenWrt-ge-22.03.0.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_x86_64_For-OpenWrt-le-21.02.7.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/uPIZj1YP/tools/OpenWrt/hawkfury_2.7_x86_64_For-OpenWrt-le-21.02.7.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/uPIZj1YP/tools/OpenWrt/hawkfury_2.7_x86_64_For-OpenWrt-le-21.02.7.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_aarch64_For-OpenWrt-ge-22.03.0.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/QE0_kKdL/tools/OpenWrt/hawkfury_2.7_aarch64_For-OpenWrt-ge-22.03.0.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/QE0_kKdL/tools/OpenWrt/hawkfury_2.7_aarch64_For-OpenWrt-ge-22.03.0.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_aarch64_For-OpenWrt-le-21.02.7.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/tXtsKbSk/tools/OpenWrt/hawkfury_2.7_aarch64_For-OpenWrt-le-21.02.7.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/tXtsKbSk/tools/OpenWrt/hawkfury_2.7_aarch64_For-OpenWrt-le-21.02.7.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_arm_For-OpenWrt-ge-22.03.0.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/JVsIlf2t/tools/OpenWrt/hawkfury_2.7_arm_For-OpenWrt-ge-22.03.0.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/JVsIlf2t/tools/OpenWrt/hawkfury_2.7_arm_For-OpenWrt-ge-22.03.0.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_arm_For-OpenWrt-le-21.02.7.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/ASVUffff/tools/OpenWrt/hawkfury_2.7_arm_For-OpenWrt-le-21.02.7.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/ASVUffff/tools/OpenWrt/hawkfury_2.7_arm_For-OpenWrt-le-21.02.7.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_mipsel_For-OpenWrt-ge-22.03.0.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/tt5tD537/tools/OpenWrt/hawkfury_2.7_mipsel_For-OpenWrt-ge-22.03.0.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/tt5tD537/tools/OpenWrt/hawkfury_2.7_mipsel_For-OpenWrt-ge-22.03.0.ipk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_2.7_mipsel_For-OpenWrt-le-21.02.7.ipk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/sx-i2vKi/tools/OpenWrt/hawkfury_2.7_mipsel_For-OpenWrt-le-21.02.7.ipk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/sx-i2vKi/tools/OpenWrt/hawkfury_2.7_mipsel_For-OpenWrt-le-21.02.7.ipk')">显示下载二维码</span>
    </div>

    <div style="margin-left: 5px; margin-top: 25px; margin-bottom: 10px; align-items: center;">
      <img
        src="../assets/media/android.png"
        style="width: 32px; height 32px;"
      >
      <span style="margin-left: 5px;">Android&nbsp;相关软件下载</span>
    </div>

    <div class="div_download">
      <span class="span_download">hawkfury_v2.7.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/KMO1U0wo/tools/Android/hawkfury_v2.7.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/KMO1U0wo/tools/Android/hawkfury_v2.7.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">hfadmin_v1.1.apk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/uWj_G6kU/tools/Android/hfadmin_v1.1.apk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/uWj_G6kU/tools/Android/hfadmin_v1.1.apk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">KernelSU_v1.0.1_11928-release.apk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/s9lPTpN1/tools/Android/KernelSU_v1.0.1_11928-release.apk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/s9lPTpN1/tools/Android/KernelSU_v1.0.1_11928-release.apk')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">Magisk-v27.0.apk</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/t8VCKbje/tools/Android/Magisk-v27.0.apk">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/t8VCKbje/tools/Android/Magisk-v27.0.apk')">显示下载二维码</span>
    </div>

    <div style="margin-top: 25px; align-items: center;">
      <span style="margin-left: 5px;">Android&nbsp;GKI&nbsp;2.0(kernel 5.10+)&nbsp;内核镜像(包含两种:&nbsp;Hawkfury+KernelSU定制版和Hawkfury+Magisk定制版)</span>
    </div>

    <div style="margin-left: 5px; margin-bottom: 5px; align-items: center;">
      <img
        src="../assets/media/KernelSU.png"
        style="width: 32px; height 32px;"
      >
      <span style="margin-left: 5px;">Hawkfury+KernelSU定制版</span>
    </div>

    <div class="div_download">
      <span class="span_timestamp">2024-08-07</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/GMyrskr2/tools/Kernel/20240807/KernelSU/android14/android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/GMyrskr2/tools/Kernel/20240807/KernelSU/android14/android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/gTpf3A6-/tools/Kernel/20240807/KernelSU/android14/android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/gTpf3A6-/tools/Kernel/20240807/KernelSU/android14/android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/p2tNMkR7/tools/Kernel/20240807/KernelSU/android14/android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/p2tNMkR7/tools/Kernel/20240807/KernelSU/android14/android14-6.1.75-2024-05_r11-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/A5N3yeMp/tools/Kernel/20240807/KernelSU/android14/android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/A5N3yeMp/tools/Kernel/20240807/KernelSU/android14/android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/LHSdal36/tools/Kernel/20240807/KernelSU/android14/android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/LHSdal36/tools/Kernel/20240807/KernelSU/android14/android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/nZ5W_CCM/tools/Kernel/20240807/KernelSU/android14/android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/nZ5W_CCM/tools/Kernel/20240807/KernelSU/android14/android14-6.1.68-2024-03_r11-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/KfDIhsr7/tools/Kernel/20240807/KernelSU/android14/android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/KfDIhsr7/tools/Kernel/20240807/KernelSU/android14/android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/pJTEzxbQ/tools/Kernel/20240807/KernelSU/android14/android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/pJTEzxbQ/tools/Kernel/20240807/KernelSU/android14/android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/AsqB1rVD/tools/Kernel/20240807/KernelSU/android14/android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/AsqB1rVD/tools/Kernel/20240807/KernelSU/android14/android14-6.1.57-2024-01_r22-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/BuqA6MGi/tools/Kernel/20240807/KernelSU/android14/android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/BuqA6MGi/tools/Kernel/20240807/KernelSU/android14/android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/chPwTK-g/tools/Kernel/20240807/KernelSU/android14/android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/chPwTK-g/tools/Kernel/20240807/KernelSU/android14/android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/9VXzFW7T/tools/Kernel/20240807/KernelSU/android14/android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/9VXzFW7T/tools/Kernel/20240807/KernelSU/android14/android14-6.1.43-2023-11_r11-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/zSDXBOeT/tools/Kernel/20240807/KernelSU/android14/android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/zSDXBOeT/tools/Kernel/20240807/KernelSU/android14/android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/cdjWWWkT/tools/Kernel/20240807/KernelSU/android14/android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/cdjWWWkT/tools/Kernel/20240807/KernelSU/android14/android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/aNBAmJ4N/tools/Kernel/20240807/KernelSU/android14/android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/aNBAmJ4N/tools/Kernel/20240807/KernelSU/android14/android14-6.1.25-2023-10_r15-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/6udpXn2K/tools/Kernel/20240807/KernelSU/android14/android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/6udpXn2K/tools/Kernel/20240807/KernelSU/android14/android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/xyzWI_WV/tools/Kernel/20240807/KernelSU/android14/android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/xyzWI_WV/tools/Kernel/20240807/KernelSU/android14/android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/gYhBto-c/tools/Kernel/20240807/KernelSU/android14/android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/gYhBto-c/tools/Kernel/20240807/KernelSU/android14/android14-5.15.148-2024-05_r12-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/xP96Mb6O/tools/Kernel/20240807/KernelSU/android14/android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/xP96Mb6O/tools/Kernel/20240807/KernelSU/android14/android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/GNQfdAfR/tools/Kernel/20240807/KernelSU/android14/android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/GNQfdAfR/tools/Kernel/20240807/KernelSU/android14/android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/XCeEJv-c/tools/Kernel/20240807/KernelSU/android14/android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/XCeEJv-c/tools/Kernel/20240807/KernelSU/android14/android14-5.15.144-2024-03_r8-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/OtYQl7NA/tools/Kernel/20240807/KernelSU/android14/android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/OtYQl7NA/tools/Kernel/20240807/KernelSU/android14/android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/cy5925ed/tools/Kernel/20240807/KernelSU/android14/android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/cy5925ed/tools/Kernel/20240807/KernelSU/android14/android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/vKhL8uwo/tools/Kernel/20240807/KernelSU/android14/android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/vKhL8uwo/tools/Kernel/20240807/KernelSU/android14/android14-5.15.137-2024-01_r17-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/2r8rOZNB/tools/Kernel/20240807/KernelSU/android14/android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/2r8rOZNB/tools/Kernel/20240807/KernelSU/android14/android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/m0WY3EO6/tools/Kernel/20240807/KernelSU/android14/android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/m0WY3EO6/tools/Kernel/20240807/KernelSU/android14/android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/AM9LgXzf/tools/Kernel/20240807/KernelSU/android14/android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/AM9LgXzf/tools/Kernel/20240807/KernelSU/android14/android14-5.15.131-2023-11_r19-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/eKOOcfiF/tools/Kernel/20240807/KernelSU/android13/android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/eKOOcfiF/tools/Kernel/20240807/KernelSU/android13/android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/BVxzpcNN/tools/Kernel/20240807/KernelSU/android13/android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/BVxzpcNN/tools/Kernel/20240807/KernelSU/android13/android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Z4NhZfws/tools/Kernel/20240807/KernelSU/android13/android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Z4NhZfws/tools/Kernel/20240807/KernelSU/android13/android13-5.15.148-2024-05_r3-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Sc1bS0Eg/tools/Kernel/20240807/KernelSU/android13/android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Sc1bS0Eg/tools/Kernel/20240807/KernelSU/android13/android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/gebn4eAE/tools/Kernel/20240807/KernelSU/android13/android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/gebn4eAE/tools/Kernel/20240807/KernelSU/android13/android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/H4SiiKrG/tools/Kernel/20240807/KernelSU/android13/android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/H4SiiKrG/tools/Kernel/20240807/KernelSU/android13/android13-5.15.144-2024-03_r7-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/nCBxjsDt/tools/Kernel/20240807/KernelSU/android13/android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/nCBxjsDt/tools/Kernel/20240807/KernelSU/android13/android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/4emCPATi/tools/Kernel/20240807/KernelSU/android13/android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/4emCPATi/tools/Kernel/20240807/KernelSU/android13/android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/a-L5OLk4/tools/Kernel/20240807/KernelSU/android13/android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/a-L5OLk4/tools/Kernel/20240807/KernelSU/android13/android13-5.15.137-2024-01_r12-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Y9JZ3q6T/tools/Kernel/20240807/KernelSU/android13/android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Y9JZ3q6T/tools/Kernel/20240807/KernelSU/android13/android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/utCi-i2d/tools/Kernel/20240807/KernelSU/android13/android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/utCi-i2d/tools/Kernel/20240807/KernelSU/android13/android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/yut3UXLe/tools/Kernel/20240807/KernelSU/android13/android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/yut3UXLe/tools/Kernel/20240807/KernelSU/android13/android13-5.15.123-2023-11_r7-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/6BPKvZTJ/tools/Kernel/20240807/KernelSU/android13/android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/6BPKvZTJ/tools/Kernel/20240807/KernelSU/android13/android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/5b2-et8d/tools/Kernel/20240807/KernelSU/android13/android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/5b2-et8d/tools/Kernel/20240807/KernelSU/android13/android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/vN-p7dzz/tools/Kernel/20240807/KernelSU/android13/android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/vN-p7dzz/tools/Kernel/20240807/KernelSU/android13/android13-5.10.209-2024-05_r6-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/63Becdk0/tools/Kernel/20240807/KernelSU/android13/android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/63Becdk0/tools/Kernel/20240807/KernelSU/android13/android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/2AlmvPv5/tools/Kernel/20240807/KernelSU/android13/android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/2AlmvPv5/tools/Kernel/20240807/KernelSU/android13/android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/6lLU5lUs/tools/Kernel/20240807/KernelSU/android13/android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/6lLU5lUs/tools/Kernel/20240807/KernelSU/android13/android13-5.10.205-2024-03_r5-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/_a5purBp/tools/Kernel/20240807/KernelSU/android13/android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/_a5purBp/tools/Kernel/20240807/KernelSU/android13/android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/dgjcNJir/tools/Kernel/20240807/KernelSU/android13/android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/dgjcNJir/tools/Kernel/20240807/KernelSU/android13/android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/lKxHH8oZ/tools/Kernel/20240807/KernelSU/android13/android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/lKxHH8oZ/tools/Kernel/20240807/KernelSU/android13/android13-5.10.198-2024-01_r11-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/brznJdy0/tools/Kernel/20240807/KernelSU/android13/android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/brznJdy0/tools/Kernel/20240807/KernelSU/android13/android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/k91iHt8U/tools/Kernel/20240807/KernelSU/android13/android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/k91iHt8U/tools/Kernel/20240807/KernelSU/android13/android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/hx5BPDQP/tools/Kernel/20240807/KernelSU/android13/android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/hx5BPDQP/tools/Kernel/20240807/KernelSU/android13/android13-5.10.189-2023-11_r10-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/wkY2vskR/tools/Kernel/20240807/KernelSU/android12/android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/wkY2vskR/tools/Kernel/20240807/KernelSU/android12/android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/vuH1Lyyo/tools/Kernel/20240807/KernelSU/android12/android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/vuH1Lyyo/tools/Kernel/20240807/KernelSU/android12/android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/f0-k5SCv/tools/Kernel/20240807/KernelSU/android12/android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/f0-k5SCv/tools/Kernel/20240807/KernelSU/android12/android12-5.10.209-2024-05_r4-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/5owRkzRE/tools/Kernel/20240807/KernelSU/android12/android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/5owRkzRE/tools/Kernel/20240807/KernelSU/android12/android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/AjQLN1aZ/tools/Kernel/20240807/KernelSU/android12/android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/AjQLN1aZ/tools/Kernel/20240807/KernelSU/android12/android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/R1YGFo8C/tools/Kernel/20240807/KernelSU/android12/android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/R1YGFo8C/tools/Kernel/20240807/KernelSU/android12/android12-5.10.205-2024-03_r7-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/bOUJPs-y/tools/Kernel/20240807/KernelSU/android12/android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/bOUJPs-y/tools/Kernel/20240807/KernelSU/android12/android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/CPlWIHCi/tools/Kernel/20240807/KernelSU/android12/android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/CPlWIHCi/tools/Kernel/20240807/KernelSU/android12/android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/pNnii3Ss/tools/Kernel/20240807/KernelSU/android12/android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/pNnii3Ss/tools/Kernel/20240807/KernelSU/android12/android12-5.10.198-2024-01_r15-ksu-v1.0.1-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div style="margin-top:40px;"></div>

    <div style="margin-left: 5px; margin-bottom: 5px; align-items: center;">
      <img
        src="../assets/media/Magisk.png"
        style="width: 32px; height 32px;"
      >
      <span style="margin-left: 5px;">Hawkfury+Magisk定制版</span>
    </div>

    <div class="div_download">
      <span class="span_timestamp">2024-08-07</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.75-2024-05_r11-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Zs9jywsJ/tools/Kernel/20240807/Magisk/android14/android14-6.1.75-2024-05_r11-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Zs9jywsJ/tools/Kernel/20240807/Magisk/android14/android14-6.1.75-2024-05_r11-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.75-2024-05_r11-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/oa81PCnv/tools/Kernel/20240807/Magisk/android14/android14-6.1.75-2024-05_r11-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/oa81PCnv/tools/Kernel/20240807/Magisk/android14/android14-6.1.75-2024-05_r11-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.75-2024-05_r11-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/TyFrbyzz/tools/Kernel/20240807/Magisk/android14/android14-6.1.75-2024-05_r11-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/TyFrbyzz/tools/Kernel/20240807/Magisk/android14/android14-6.1.75-2024-05_r11-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.68-2024-03_r11-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/3kP0afVt/tools/Kernel/20240807/Magisk/android14/android14-6.1.68-2024-03_r11-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/3kP0afVt/tools/Kernel/20240807/Magisk/android14/android14-6.1.68-2024-03_r11-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.68-2024-03_r11-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/i1NCym-V/tools/Kernel/20240807/Magisk/android14/android14-6.1.68-2024-03_r11-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/i1NCym-V/tools/Kernel/20240807/Magisk/android14/android14-6.1.68-2024-03_r11-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.68-2024-03_r11-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/ViXiv79X/tools/Kernel/20240807/Magisk/android14/android14-6.1.68-2024-03_r11-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/ViXiv79X/tools/Kernel/20240807/Magisk/android14/android14-6.1.68-2024-03_r11-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.57-2024-01_r22-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/HclXIZTq/tools/Kernel/20240807/Magisk/android14/android14-6.1.57-2024-01_r22-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/HclXIZTq/tools/Kernel/20240807/Magisk/android14/android14-6.1.57-2024-01_r22-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.57-2024-01_r22-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/CnfLj33T/tools/Kernel/20240807/Magisk/android14/android14-6.1.57-2024-01_r22-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/CnfLj33T/tools/Kernel/20240807/Magisk/android14/android14-6.1.57-2024-01_r22-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.57-2024-01_r22-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/tAbB5Chf/tools/Kernel/20240807/Magisk/android14/android14-6.1.57-2024-01_r22-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/tAbB5Chf/tools/Kernel/20240807/Magisk/android14/android14-6.1.57-2024-01_r22-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.43-2023-11_r11-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/pqkGh65m/tools/Kernel/20240807/Magisk/android14/android14-6.1.43-2023-11_r11-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/pqkGh65m/tools/Kernel/20240807/Magisk/android14/android14-6.1.43-2023-11_r11-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.43-2023-11_r11-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/GtxNuxv5/tools/Kernel/20240807/Magisk/android14/android14-6.1.43-2023-11_r11-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/GtxNuxv5/tools/Kernel/20240807/Magisk/android14/android14-6.1.43-2023-11_r11-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.43-2023-11_r11-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/6HjYEAe1/tools/Kernel/20240807/Magisk/android14/android14-6.1.43-2023-11_r11-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/6HjYEAe1/tools/Kernel/20240807/Magisk/android14/android14-6.1.43-2023-11_r11-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.25-2023-10_r15-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/qnPAb4DJ/tools/Kernel/20240807/Magisk/android14/android14-6.1.25-2023-10_r15-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/qnPAb4DJ/tools/Kernel/20240807/Magisk/android14/android14-6.1.25-2023-10_r15-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.25-2023-10_r15-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/FJ5ZyJY9/tools/Kernel/20240807/Magisk/android14/android14-6.1.25-2023-10_r15-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/FJ5ZyJY9/tools/Kernel/20240807/Magisk/android14/android14-6.1.25-2023-10_r15-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-6.1.25-2023-10_r15-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/p8l7ixw0/tools/Kernel/20240807/Magisk/android14/android14-6.1.25-2023-10_r15-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/p8l7ixw0/tools/Kernel/20240807/Magisk/android14/android14-6.1.25-2023-10_r15-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.148-2024-05_r12-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/p0VoayuC/tools/Kernel/20240807/Magisk/android14/android14-5.15.148-2024-05_r12-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/p0VoayuC/tools/Kernel/20240807/Magisk/android14/android14-5.15.148-2024-05_r12-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.148-2024-05_r12-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/_OzhGw_Y/tools/Kernel/20240807/Magisk/android14/android14-5.15.148-2024-05_r12-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/_OzhGw_Y/tools/Kernel/20240807/Magisk/android14/android14-5.15.148-2024-05_r12-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.148-2024-05_r12-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/mGY9vsfE/tools/Kernel/20240807/Magisk/android14/android14-5.15.148-2024-05_r12-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/mGY9vsfE/tools/Kernel/20240807/Magisk/android14/android14-5.15.148-2024-05_r12-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.144-2024-03_r8-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/ZA86X7NW/tools/Kernel/20240807/Magisk/android14/android14-5.15.144-2024-03_r8-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/ZA86X7NW/tools/Kernel/20240807/Magisk/android14/android14-5.15.144-2024-03_r8-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.144-2024-03_r8-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Nu_etXqh/tools/Kernel/20240807/Magisk/android14/android14-5.15.144-2024-03_r8-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Nu_etXqh/tools/Kernel/20240807/Magisk/android14/android14-5.15.144-2024-03_r8-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.144-2024-03_r8-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/omcPbIab/tools/Kernel/20240807/Magisk/android14/android14-5.15.144-2024-03_r8-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/omcPbIab/tools/Kernel/20240807/Magisk/android14/android14-5.15.144-2024-03_r8-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.137-2024-01_r17-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/KkFFOlqd/tools/Kernel/20240807/Magisk/android14/android14-5.15.137-2024-01_r17-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/KkFFOlqd/tools/Kernel/20240807/Magisk/android14/android14-5.15.137-2024-01_r17-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.137-2024-01_r17-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/GBXDDdkT/tools/Kernel/20240807/Magisk/android14/android14-5.15.137-2024-01_r17-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/GBXDDdkT/tools/Kernel/20240807/Magisk/android14/android14-5.15.137-2024-01_r17-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.137-2024-01_r17-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Dq1S0O9a/tools/Kernel/20240807/Magisk/android14/android14-5.15.137-2024-01_r17-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Dq1S0O9a/tools/Kernel/20240807/Magisk/android14/android14-5.15.137-2024-01_r17-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.131-2023-11_r19-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/95C4-tAO/tools/Kernel/20240807/Magisk/android14/android14-5.15.131-2023-11_r19-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/95C4-tAO/tools/Kernel/20240807/Magisk/android14/android14-5.15.131-2023-11_r19-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.131-2023-11_r19-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/gHq2zmbM/tools/Kernel/20240807/Magisk/android14/android14-5.15.131-2023-11_r19-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/gHq2zmbM/tools/Kernel/20240807/Magisk/android14/android14-5.15.131-2023-11_r19-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android14-5.15.131-2023-11_r19-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Li-l6Rpm/tools/Kernel/20240807/Magisk/android14/android14-5.15.131-2023-11_r19-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Li-l6Rpm/tools/Kernel/20240807/Magisk/android14/android14-5.15.131-2023-11_r19-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.148-2024-05_r3-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/hz6Uaydw/tools/Kernel/20240807/Magisk/android13/android13-5.15.148-2024-05_r3-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/hz6Uaydw/tools/Kernel/20240807/Magisk/android13/android13-5.15.148-2024-05_r3-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.148-2024-05_r3-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/UxLb3Q79/tools/Kernel/20240807/Magisk/android13/android13-5.15.148-2024-05_r3-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/UxLb3Q79/tools/Kernel/20240807/Magisk/android13/android13-5.15.148-2024-05_r3-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.148-2024-05_r3-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Zykuj2sA/tools/Kernel/20240807/Magisk/android13/android13-5.15.148-2024-05_r3-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Zykuj2sA/tools/Kernel/20240807/Magisk/android13/android13-5.15.148-2024-05_r3-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.144-2024-03_r7-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/Ul11Oy6W/tools/Kernel/20240807/Magisk/android13/android13-5.15.144-2024-03_r7-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/Ul11Oy6W/tools/Kernel/20240807/Magisk/android13/android13-5.15.144-2024-03_r7-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.144-2024-03_r7-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/rmqNb1Z3/tools/Kernel/20240807/Magisk/android13/android13-5.15.144-2024-03_r7-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/rmqNb1Z3/tools/Kernel/20240807/Magisk/android13/android13-5.15.144-2024-03_r7-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.144-2024-03_r7-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/xiOW75Fs/tools/Kernel/20240807/Magisk/android13/android13-5.15.144-2024-03_r7-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/xiOW75Fs/tools/Kernel/20240807/Magisk/android13/android13-5.15.144-2024-03_r7-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.137-2024-01_r12-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/gZEYeZGK/tools/Kernel/20240807/Magisk/android13/android13-5.15.137-2024-01_r12-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/gZEYeZGK/tools/Kernel/20240807/Magisk/android13/android13-5.15.137-2024-01_r12-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.137-2024-01_r12-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/7W55Onj3/tools/Kernel/20240807/Magisk/android13/android13-5.15.137-2024-01_r12-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/7W55Onj3/tools/Kernel/20240807/Magisk/android13/android13-5.15.137-2024-01_r12-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.137-2024-01_r12-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/U8awoFcw/tools/Kernel/20240807/Magisk/android13/android13-5.15.137-2024-01_r12-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/U8awoFcw/tools/Kernel/20240807/Magisk/android13/android13-5.15.137-2024-01_r12-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.123-2023-11_r7-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/v35CdN_O/tools/Kernel/20240807/Magisk/android13/android13-5.15.123-2023-11_r7-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/v35CdN_O/tools/Kernel/20240807/Magisk/android13/android13-5.15.123-2023-11_r7-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.123-2023-11_r7-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/AXX0pf-D/tools/Kernel/20240807/Magisk/android13/android13-5.15.123-2023-11_r7-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/AXX0pf-D/tools/Kernel/20240807/Magisk/android13/android13-5.15.123-2023-11_r7-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.15.123-2023-11_r7-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/bYG1DTmH/tools/Kernel/20240807/Magisk/android13/android13-5.15.123-2023-11_r7-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/bYG1DTmH/tools/Kernel/20240807/Magisk/android13/android13-5.15.123-2023-11_r7-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.209-2024-05_r6-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/FO2SiHmi/tools/Kernel/20240807/Magisk/android13/android13-5.10.209-2024-05_r6-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/FO2SiHmi/tools/Kernel/20240807/Magisk/android13/android13-5.10.209-2024-05_r6-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.209-2024-05_r6-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/5l5zZuQm/tools/Kernel/20240807/Magisk/android13/android13-5.10.209-2024-05_r6-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/5l5zZuQm/tools/Kernel/20240807/Magisk/android13/android13-5.10.209-2024-05_r6-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.209-2024-05_r6-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/IYmSXf6T/tools/Kernel/20240807/Magisk/android13/android13-5.10.209-2024-05_r6-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/IYmSXf6T/tools/Kernel/20240807/Magisk/android13/android13-5.10.209-2024-05_r6-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.205-2024-03_r5-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/RimXYcAU/tools/Kernel/20240807/Magisk/android13/android13-5.10.205-2024-03_r5-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/RimXYcAU/tools/Kernel/20240807/Magisk/android13/android13-5.10.205-2024-03_r5-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.205-2024-03_r5-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/wLv3v6pu/tools/Kernel/20240807/Magisk/android13/android13-5.10.205-2024-03_r5-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/wLv3v6pu/tools/Kernel/20240807/Magisk/android13/android13-5.10.205-2024-03_r5-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.205-2024-03_r5-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/WUnKx34Z/tools/Kernel/20240807/Magisk/android13/android13-5.10.205-2024-03_r5-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/WUnKx34Z/tools/Kernel/20240807/Magisk/android13/android13-5.10.205-2024-03_r5-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.198-2024-01_r11-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/ax6_XTtH/tools/Kernel/20240807/Magisk/android13/android13-5.10.198-2024-01_r11-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/ax6_XTtH/tools/Kernel/20240807/Magisk/android13/android13-5.10.198-2024-01_r11-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.198-2024-01_r11-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/pGkf9QoQ/tools/Kernel/20240807/Magisk/android13/android13-5.10.198-2024-01_r11-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/pGkf9QoQ/tools/Kernel/20240807/Magisk/android13/android13-5.10.198-2024-01_r11-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.198-2024-01_r11-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/qtIXLtb3/tools/Kernel/20240807/Magisk/android13/android13-5.10.198-2024-01_r11-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/qtIXLtb3/tools/Kernel/20240807/Magisk/android13/android13-5.10.198-2024-01_r11-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.189-2023-11_r10-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/wzJfAor-/tools/Kernel/20240807/Magisk/android13/android13-5.10.189-2023-11_r10-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/wzJfAor-/tools/Kernel/20240807/Magisk/android13/android13-5.10.189-2023-11_r10-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.189-2023-11_r10-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/V9ucpvON/tools/Kernel/20240807/Magisk/android13/android13-5.10.189-2023-11_r10-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/V9ucpvON/tools/Kernel/20240807/Magisk/android13/android13-5.10.189-2023-11_r10-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android13-5.10.189-2023-11_r10-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/jKCSb9x9/tools/Kernel/20240807/Magisk/android13/android13-5.10.189-2023-11_r10-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/jKCSb9x9/tools/Kernel/20240807/Magisk/android13/android13-5.10.189-2023-11_r10-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.209-2024-05_r4-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/TRfvy1Be/tools/Kernel/20240807/Magisk/android12/android12-5.10.209-2024-05_r4-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/TRfvy1Be/tools/Kernel/20240807/Magisk/android12/android12-5.10.209-2024-05_r4-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.209-2024-05_r4-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/iJK-tDdy/tools/Kernel/20240807/Magisk/android12/android12-5.10.209-2024-05_r4-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/iJK-tDdy/tools/Kernel/20240807/Magisk/android12/android12-5.10.209-2024-05_r4-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.209-2024-05_r4-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/arAne4IU/tools/Kernel/20240807/Magisk/android12/android12-5.10.209-2024-05_r4-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/arAne4IU/tools/Kernel/20240807/Magisk/android12/android12-5.10.209-2024-05_r4-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.205-2024-03_r7-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/BCxmBFHQ/tools/Kernel/20240807/Magisk/android12/android12-5.10.205-2024-03_r7-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/BCxmBFHQ/tools/Kernel/20240807/Magisk/android12/android12-5.10.205-2024-03_r7-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.205-2024-03_r7-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/AJ4Q42tF/tools/Kernel/20240807/Magisk/android12/android12-5.10.205-2024-03_r7-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/AJ4Q42tF/tools/Kernel/20240807/Magisk/android12/android12-5.10.205-2024-03_r7-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.205-2024-03_r7-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/B4TgEiGe/tools/Kernel/20240807/Magisk/android12/android12-5.10.205-2024-03_r7-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/B4TgEiGe/tools/Kernel/20240807/Magisk/android12/android12-5.10.205-2024-03_r7-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.198-2024-01_r15-Magisk-boot.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/5qaEiFPA/tools/Kernel/20240807/Magisk/android12/android12-5.10.198-2024-01_r15-Magisk-boot.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/5qaEiFPA/tools/Kernel/20240807/Magisk/android12/android12-5.10.198-2024-01_r15-Magisk-boot.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.198-2024-01_r15-Magisk-boot-gz.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/AtpLuCxS/tools/Kernel/20240807/Magisk/android12/android12-5.10.198-2024-01_r15-Magisk-boot-gz.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/AtpLuCxS/tools/Kernel/20240807/Magisk/android12/android12-5.10.198-2024-01_r15-Magisk-boot-gz.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_download">
      <span class="span_download">android12-5.10.198-2024-01_r15-Magisk-boot-lz4.img.zip</span>
      <a class="a_download" href="https://drive.hawkfury.com/api/public/dl/C_BJvHvX/tools/Kernel/20240807/Magisk/android12/android12-5.10.198-2024-01_r15-Magisk-boot-lz4.img.zip">点击下载</a>
      <span class="span_click" @click="showQRcode('https://drive.hawkfury.com/api/public/dl/C_BJvHvX/tools/Kernel/20240807/Magisk/android12/android12-5.10.198-2024-01_r15-Magisk-boot-lz4.img.zip')">显示下载二维码</span>
    </div>

    <div class="div_gap">
    </div>
    <b-modal ref="modal" hide-footer size="sm" centered>
      <template v-slot:modal-title>下载二维码</template>
      <div class="code" style="text-align:center; margin-bottom: 20px;"> 
        <vue-qr  
          ref="qrCode"   
          :text="textValue"
          :logoSrc="`${publicPath}hawkfury_64.png`"
          :logoScale="64" 
          :size="200" 
          :margin="0"
         />  
      </div>
      <button class="button-close" @click="hideModal">关闭</button>
    </b-modal>


  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: 'Tools',
  components: {
    VueQr
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      textValue: ''
    }
  },
  methods: {
    showQRcode(val) {
      this.textValue = val;
      this.$refs['modal'].show();
    },

    hideModal() {
      this.$refs['modal'].hide();
    }

  }
}
</script>

<style scoped>
#page1{
  margin-left: 110px;
  padding: 0;
  height: 600px;
  width: 90%;
}

.p-a {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #2185d0!important;
  font-weight: bold;
  font-size: 24px;
}

.div_download {
  margin-left: 5px;
  align-items: center;
}

.div_gap {
  padding-bottom: 100px;
}

.span_download {
  width: 470px;
  display: inline-block;
}

.span_timestamp {
  width: 470px;
  display: inline-block;
  color: #4682B4;
}

.a_download {
  cursor: default;
  width: 130px;
  display: inline-block;
}

.a_download:link, .a_download:visited {
  color: #191970;
  text-decoration: none;
}

.a_download:hover {
  color: #87CEFA;
  text-decoration: none;
}

.a_downloada:active {
  color: #191970;
  text-decoration: none;
}

.span_click {
  color:#191970;
  cursor:default;
}

.span_click:hover {
  color:      #87CEFA;
}

.button-close {
  min-width: 100%;
  height: 35px;
  display:block;
  border-radius:3px;
  border:none;
  background-color:#1A8CDB;
  color:#fff;
  font-size:16px;
}

</style>

