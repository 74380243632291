<template>
  <div id="page1">
    <p class="p-a">流量价格表 单位: USDT (1 USDT = 1美元)</p>
    <table>
      <tr>
        <th class="slash-wrap">
          <span class="left">流量</span>
          <span class="slash"></span>
          <span class="right">时长</span>
        </th>
        <th class="t-body" v-for="(v, i) in duration" :key="i">{{ v }}个月</th>
      </tr>
      <tr v-for="(val1, index1) in data_price" :key="index1">
        <td>每月{{ val1.data }}GB</td>
        <td align="right" v-for="(val2, index2) in duration" :key="index2">{{ val1.price[val2] }}</td>
      </tr>
    </table> 

    <div style="margin-top: 10px;margin-left: 0;align-items: center;">
      <span style="margin-right: 5px;">请选择每月流量套餐:</span>
      <select id="plan_select" v-model="plan_selected" @change='getValue'>
        <option v-for="(item, i) in plan"  :value="item">{{ item }}GB</option>
      </select>
  
      <span style="margin-left: 30px; margin-right: 10px">请选购买时长:</span>
      <select id="duration_select" v-model="duration_selected" @change='getValue'>
        <option v-for="(item, i) in duration"  :value="item">{{ item }}个月</option>
      </select>
  
      <span style="margin-left: 30px; margin-right: 10px">请选服务器所在位置:</span>
      <select id="geo_select" v-model="geo_selected" @change='getGeo' >
        <option v-for="(item, i) in geo"  :value="item.geoid + '|' + item.geoname">{{ item.geoid + '|' + item.geoname }}</option>
      </select>
    </div>

    <div style="margin-top: 160px; width: 600px; align-items: center;">
      <span style="margin-right: 5px;">您需要付款:</span>
      <span style="color:#003D79;font-weight:bold;">{{ price }}</span>
      <span style="margin-left: 5px;">USDT</span>
    </div>


    <div class="payinfo" style="margin-top: 10px;">
      <span style="height:50px;line-height:50px;">支付方式:</span>
      <div class="waiwei">
        <img
          src="../assets/media/usdt_tron.png"
          style="width: 32px;height 32px;margin-left: 5px;float: left;"
        >
        <span style="cursor:default;float:right;margin-left:5px;">USDT-TRC20</span>
      </div>
      <button  @click="alipay()">立即支付</button>
    </div>

    <div v-if="message" class="message">
      <p>{{ message }}</p>
    </div>

  </div>
</template>

<script>
export default {
    name: 'Shop',
    data() {
        return {
            plan_selected: '',
            duration_selected: '',
            geo_selected: '',
            geoid: 1,
            geoname: '',
            price: '',
            duration: [],
            plan: [],
            geo: [],
            data_price: [],
            message: ''
        };
    },
    methods: {
        alipay() {
            var config = {
                method: 'post',
                url: 'alipay',
                data: {
                    username: this.$store.state.username, // 客户端登录的用户名 电子邮箱
                    plan: this.plan_selected, // 每月流量套餐
                    duration: this.duration_selected, // 时长
                    geoid: this.geoid, // 服务器所在位置id
                    geoname: this.geoname, // 服务器所在位置名称
                    price: this.price
                }
            };
            this.$axios(config)
                .then(response => {
                    if(response.status == 200) {
                        window.location.href = response.data.pay_url;
                    } else {
                        this.message = response.data.message;
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.message = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
                    } else if (error.request) {
                        this.message = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS) 
                    } else {
                        this.message = '发生未知错误';
                    }
                });
        },


        getValue() {
            for(const elem of this.data_price) {
                if(elem["data"] == this.plan_selected) {
                    this.price = elem["price"][this.duration_selected];
                }
            }
        },

        getGeo() {
            this.geoid = this.geo_selected.split('|')[0];
            this.geoname = this.geo_selected.split('|')[1];
        }
    },

    beforeCreate() {
        var config = {
            method: 'post',
            url: 'price',
            data: {}
        };
        this.$axios(config)
            .then(response => {
                if(response.status == 200) {
                    this.duration = response.data.duration;
                    this.plan = response.data.plan;
                    this.geo = response.data.geo; // [{'geoid': 1, 'geoname': '美国'}, {'geoid': 2, 'geoname': '新加坡'}]
                    this.data_price = response.data.data_price;
                    this.plan_selected = this.plan[0];
                    this.duration_selected = this.duration[0];
                    this.geoid = this.geo[0].geoid;
                    this.geoname = this.geo[0].geoname;
                    this.geo_selected = this.geoid + '|' + this.geoname;
                }
            })
            .catch(error => {
                if (error.response) {
                    this.message = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
                } else if (error.request) {
                    this.message = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS) 
                } else {
                    this.message = '发生未知错误';
                }
            });

    },

    watch: {
        'plan_selected': function() {
            this.getValue();
        },
        'duration_select': function() {
            this.getValue();
        }
    }
};
</script>

<style scoped>
#page1{
  margin-left: 110px;
  padding: 0;
  height: 600px;
  width: 90%;
}

.p-a {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #2185d0!important;
  font-weight: bold;
  font-size: 24px;
}

table {
  border-collapse: collapse;
}

th,td {
  border: 1px solid #666;
  padding: 5px;
}

.slash-wrap {
  position: relative;
  box-sizing: border-box;
  width: 150px;
  height: 80px;
 }

.t-body {
  width: 148px;
}

.slash {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 170px;
  height: 1px;
  background-color:#000;
  transform: rotate(28.072486935852954deg); 
  transform-origin: top left;
}

.left {
  position: absolute;
  left: 15px;
  bottom: 15px;
}

.right {
  position: absolute;
  right: 15px;
  top: 15px;
} 

.payinfo {
  display: flex;
  margin: 0;
  padding: 0;
  width: 600px;
  height: 40px;
  align-items: center;
}

.waiwei {
  border: 1px solid #3a8ee6;
  margin-left: 10px;
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

button{
  margin-left: 100px;
  width:100px;
  height:40px;
  border-radius:3px;
  border:none;
  background-color:#1A8CDB;
  color:#fff;
  font-size:16px;
}

.message{
  margin-top: 10px;
  width:600px;
}

</style>
