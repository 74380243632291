<template>
  <div id="app">
    <Navbar />
    <div class="content">
      <router-view />
    </div>
    <Footer />
  </div>
</template>
<script>
  import Navbar from './components/Navbar.vue'
  import Footer from './components/Footer.vue'
  import Home from './views/Home.vue'
  import Tools from './views/Tools.vue'
  import Notification from './views/Notification.vue'
  import Account from './views/Account.vue'
  import Login from './views/Login.vue'
  import Shop from './views/Shop.vue'
  import Payment from './views/Payment.vue'
  export default {
    name: 'App',
    components: {
      Navbar,
      Footer,
      Home,
      Tools,
      Notification,
      Account,
      Login,
      Shop,
      Payment
    }
  }
</script>
<style scoped>
#app {
  position: relative;
  min-height: 100vh;
  padding-top: 60px;
}


        .content {
            position: relative;
            height: calc(100% - 60px);
        }

</style>
