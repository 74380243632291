<template>
  <div class="login-wrap">
    <h2>登录</h2>

    <input type="text" placeholder="请输入您的电子邮箱" v-model="username" required ref="email">
    <span @click.self="sendEmail()">将登录密码发送到电子邮箱</span>
    <input type="password" placeholder="请输入密码" v-model="password" required>

    <button @click="login()">登录</button>

    <div v-if="message" class="message">
      <p>{{ message }}</p>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      message: ''
    }
  },
  methods: {
    sendEmail() {
      let mailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (mailReg.test(this.username)) {
        var params = {
          username: this.username
        };
        var config = {
          method: 'get',
          url: 'passcode',
          params: params
        };
        this.$axios(config)
          .then(response => {
            if(response.status == 200) {
              this.message = '已将登录密码发送到您的电子邮箱';
            }
            if(response.status == 202) {
              this.message = '发送邮件失败';
            }
          })
          .catch(error => {
            if (error.response) {
              this.message = '服务器响应错误';
            } else if (error.request) {
              this.message = '请求未发出或未收到服务器响应';
            } else {
              this.message = '发生未知错误';
            }
          });
      } else {
        this.message = '请输入有效的电子邮件地址';
        this.$refs.email.focus();
      }
    },
    login() {
      var config = {
        method: 'post',
        url: 'login',
        data: {
          username: this.username,
          password: this.password
        }
      };
      this.$axios(config)
        .then(response => {
          if(response.status == 200) {
            this.$store.commit('login', {token: response.data.token, username: response.data.username});
            this.$router.push(this.$route.query.redirect || '/'); // 跳转到之前的页面
          }
          if(response.status == 202) {
            this.message = '用户名或密码错误';
          }
        })
        .catch(error => {
          if (error.response) {
            this.message = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
          } else if (error.request) {
            this.message = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS) 
          } else {
            this.message = '发生未知错误-分散的卡夫卡的死';
          }
        });
    }
  }
};

</script>

<style type="text/css" scoped>
.login-wrap{
  text-align:center;
  position: fixed;
  top: 50%;
  left: 50%;
  width:50%;
  height: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
}

input{
  display:block; 
  width:300px; 
  height:40px; 
  line-height:40px; 
  margin:0 auto; 
  margin-bottom: 10px; 
  outline:none; 
  border:1px solid #888; 
  padding:10px; 
  box-sizing:border-box;
}

span{
  text-align:left;
  width:300px;
  display:block;
  margin:0 auto;
  cursor:pointer;
  color:#1DA1F2;
}

span:hover{
  color:#1DA1F2;
}

button{
  display:block; 
  width:300px; 
  height:40px; 
  border-radius:3px;
  line-height: 40px; 
  margin:0 auto; 
  border:none; 
  background-color:#1A8CDB; 
  color:#fff; 
  font-size:16px; 
  margin-bottom:5px;
}

.message{
  text-align:left;
  width:300px;
  display:block;
  margin:0 auto;
}

p{
  color:red;
}

</style>
