import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import Tools from '../views/Tools.vue'
import Notification from '../views/Notification.vue'
import Account from '../views/Account.vue'
import Login from '../views/Login.vue'
import Payment from '../views/Payment.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment 
  },
  {
    path: '/tools',
    name: 'Tools',
    component: Tools
  },
  {
    path: '/notification',
    name: 'Notification',
    component: Notification 
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login 
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.state.isLogin) {
      next();
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    //next({path: to.path, query: to.query});
    next();
  }

})



export default router
