<template>
  <div class="tablePage" v-cloak>
    <p class="p-a">当前用户: {{ this.$store.state.username }}</p>

    <!-- 表格-->
    <ve-table
      :columns="tableConfig.columns"
      :table-data="tableConfig.tableData"
      :paging-index="(pageIndex-1)*pageSize"
      :cell-style-option="cellStyleOption"
      :border-x="true"
      :border-y="true">
    </ve-table>

    <!-- 分页-->
    <ve-pagination
      @on-page-number-change="pageChange"
      @on-page-size-change="pageSizeChange"
      :total="dataNum"
      :page-size="pageSize"
      :layout="['total', 'prev', 'pager', 'next', 'sizer', 'jumper']">
    </ve-pagination>

    <div v-if="msg1" class="message">
      {{ msg1 }}
    </div>

    <b-modal ref="modal" hide-footer centered>
      <template v-slot:modal-title>查看授权码</template>
      <div>
        <div class="div-inline" ref="container">
          <button class="button-demo" @click="doCopy" style="margin-right: 5px;">复制</button>
          <button class="button-demo" @click="doDownload()">下载</button>
          <div v-if="msg2" class="message">
            {{ msg2 }}
          </div>
        </div> 
        <textarea ref="vuetext" v-model="key" rows="10" style="min-width: 100%;resize: none;" readonly></textarea>
      </div>
      <button class="button-close" @click="hideModal">关闭</button>
    </b-modal>

  </div>
</template>

<script>
import "vue-easytable/libs/theme-default/index.css"
import { VeTable, VePagination } from "vue-easytable"
import { saveAs } from 'file-saver' // npm install file-saver

//加入中文支持
import { VeLocale } from "vue-easytable";
import zhCN from "vue-easytable/libs/locale/lang/zh-CN.js";
VeLocale.use(zhCN);

export default {
  components: {
    VeTable,
    VePagination
  },
  data () {
    return {
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (rowIndex === 0) {
            return "table-header-cell-class";
          }
        },
      },

      msg1: '',
      msg2: '',
      key: '',
      allData: [],
      pageIndex: 1, /*页码*/
      pageSize: 10, /*每页条数*/
      dataNum: 0, /*数据总条数*/
      tableConfig: {
        tableData: [],
        columns: [
          {
            field: 'order_id',
            key: 'a',
            title: '商户订单号',
            align: 'left'
          },
          {
            field: 'subject',
            key: 'b',
            title: '商品代码',
            align: 'left'
          },
          {
            field: 'plan',
            key: 'c',
            title: '套餐流量',
            align: 'left'
          },
          {
            field: 'carry_data',
            key: 'd',
            title: '上月结转流量',
            align: 'left'
          },
          {
            field: 'used_data',
            key: 'e',
            title: '本月已用流量',
            align: 'left'
          },
          {
            field: 'iat_time',
            key: 'f',
            title: '支付时间',
            align: 'left'
          },
          {
            field: 'nbf_date',
            key: 'g',
            title: '生效日期',
            align: 'left'
          },
          {
            field: 'exp_date',
            key: 'h',
            title: '失效日期',
            align: 'left'
          },
          {
            field: "download",
            key: "g",
            title: "下载授权码",
            align: "center",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
              return (
                <div class="div-inline1">
                  <button
                    class="button-demo"
                    on-click={() => this.download(rowIndex)}
                    style="margin-right: 5px;"
                  >下载</button>
                  <button
                    class="button-demo"
                    on-click={() => this.view(rowIndex)}
                  >查看</button>
                </div>
              );
            },
          }
        ]
      }
    }
  },
  created () {
    this.getAllData();
  },
  methods: {
    doCopy() {
      var _this = this; // 窗口焦点变化,this指向也发生了变化,这里保留原来的
      _this.$copyText(_this.key, _this.$refs.container).then(
        function(e) {
          _this.msg2 = "已复制到剪贴板!";
        },
        function(e) {
          _this.msg2 = "复制失败!";
        }
      );
    },

    doDownload() {
      var blob = new Blob([this.key], {type: "text/plain;charset=utf-8"});
      saveAs(blob, 'key.txt');
      this.msg2 = "下载成功!";
    },

    handleCopyStatus(status) {
      this.copySucceeded = status;
    },

    hideModal() {
      this.$refs['modal'].hide();
    },

    async view(rowIndex) {
      this.msg1 = ''; // 清空消息, 防止本窗口显示上次的消息
      this.msg2 = ''; // 必须清理, 否则模态窗口还会显示上次的消息

      var config = {
        method: 'post',
        url: 'download1',
        data: {
          order_id: this.tableConfig.tableData[rowIndex]['order_id'] // 商户订单号
        }
      };

      await this.$axios(config)
        .then(response => {
          if(response.status == 200) {
            this.key = response.data.key;
            this.$refs['modal'].show();
          }
          if(response.status == 202) {
            this.msg1 = response.data.message;
          }
        })
        .catch(error => {
          if (error.response) {
              this.msg1 = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
          } else if (error.request) {
              this.msg1 = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS)
          } else {
              this.msg1 = '发生未知错误';
          }
        });
    },
    
    download(rowIndex) {
      this.msg1 = ''; // 清空模态窗口消息,防止显示上次窗口的消息
      var config = {
        method: 'post',
        url: 'download1',
        data: {
          order_id: this.tableConfig.tableData[rowIndex]['order_id'] // 商户订单号
        }
      };

      this.$axios(config)
        .then(response => {
          if(response.status == 200) {
            var blob = new Blob([response.data.key], {type: "text/plain;charset=utf-8"});
            var filename = response.data.filename;
            saveAs(blob, filename);
          }
          if(response.status == 202) {
            this.msg1 = response.data.message;
          }
        })
        .catch(error => {
          if (error.response) {
              this.msg1 = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
          } else if (error.request) {
              this.msg1 = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS)
          } else {
              this.msg1 = '发生未知错误';
          }
        });
    },

    headerCellClass(column, rowIndex) {
      if (rowIndex === 0) {
        return "table-header-cell-class";
      }
    },

    /*初始化时获得全部数据*/
    async getAllData() {
      var config = {
        method: 'post',
        url: 'account',
        data: {
          username: this.$store.state.username, // 客户端登录的用户名 电子邮箱
        }
      };

      await this.$axios(config)
        .then(response => {
          if(response.status == 200) {
            const jsonString = response.data.data;
            this.allData = JSON.parse(jsonString);
            this.dataNum = this.allData.length;
            this.getTableData();
          }
        })
        .catch(error => {
          if (error.response) {
              this.msg1 = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
          } else if (error.request) {
              this.msg1 = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS)
          } else {
              this.msg1 = '发生未知错误';
          }   
        });
    },

    /*分页处理*/
    getTableData () {
      this.tableConfig.tableData = this.allData.slice(
        (this.pageIndex - 1) * this.pageSize,
        this.pageIndex * this.pageSize
      );
    },

    /*页码触发更新*/
    pageChange (pageIndex) {
      this.pageIndex = pageIndex
      this.getTableData()
      console.log(pageIndex)
    },

    /*每页条数触发更新*/
    pageSizeChange (pageSize) {
      this.pageIndex = 1
      this.pageSize = pageSize
      this.getTableData()
    }
  }
}
</script>


<style>
.tablePage {
  margin-left: 110px;
  padding: 0;
  height: 600px;
  width: 86.5%;
}

.p-a {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #2185d0!important;
  font-weight: bold;
  font-size: 24px;
}

.table-header-cell-class {
  background: #C0C0C0 !important;
  font-weight: bold !important;
}

.button-demo {
  display:block;
  text-align:center;
  width:60px;
  height:35px;
  border-radius:3px;
  border:none;
  background-color:#1A8CDB;
  color:#fff;
  font-size:16px;
}

.button-close {
  min-width: 100%;
  height: 35px;
  display:block;
  border-radius:3px;
  border:none;
  background-color:#1A8CDB;
  color:#fff;
  font-size:16px;
}

.div-inline { 
  align: left;
  display: flex;
  flex-direction: row; 
  margin-bottom: 5px;
}

.div-inline1 { 
  justify-content: center; /* 水平居中 */
  display: flex;
  flex-direction: row; 
}

.message {
  color: red;
  margin-left: 3px;
}

[v-cloak] {
  display: none !important;
}

</style>
