<template>
  <b-navbar toggleable="lg" type="dark" class="navbar-custom">
    <img id="logo" class="d-inline-block mr-1" src="../assets/hawkfury.png" style="margin-left: 90px;">
    <span style="margin-right: 10px;">鹰之怒</span>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/" exact-active-class="active">主页</b-nav-item>
        <b-nav-item to="/shop" exact-active-class="active">商店</b-nav-item>
        <b-nav-item to="/tools" exact-active-class="active">下载</b-nav-item>
        <b-nav-item to="/notification" exact-active-class="active">安装和使用说明</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto" style="margin-right: 90px;">
        <b-nav-item to="/account" exact-active-class="active">我的账户</b-nav-item>
        <b-button size="sm" class="my-2 my-sm-0" type="submit" v-show="this.$store.state.isLogin" @click="logout()">注销</b-button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    logout() {
      this.$store.commit('logout');
    }
  }
}
</script>

<style scoped>
img{
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;  
}


.navbar-custom {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: #1DA1F2;
  z-index: 9999;
}
</style>
