<template>
  <div id="page">
    <p class="p-a">欢迎访问鹰之怒官网</p>
    <p>鹰之怒发布了! 全体中国大陆互联网用户的福音!</p>
    <p>如何您是身处中国大陆的外贸工作者, 有了鹰之怒您不再会发愁如何通过互联网与海外的业务伙伴取得联系</p>
    <p>如何您是身处中国大陆的政府工作人员, 有了鹰之怒您可以通过互联网了解所有政治热点事件的背景, 人物关系, 帮你进入真正的消息海</p>
    <p>如何您是身处中国大陆的科技工作者, 有了鹰之怒您不再用为了通过互联网获得海外技术资源而绞尽脑汁, 它让你所有的工作丝滑顺畅</p>
    <p>如何您是身处中国大陆的学生, 有了鹰之怒您可以通过互联网聆听哈佛大学、耶鲁大学、斯坦福大学、剑桥大学...所有的课程</p>
    <p>如何您是身处中国大陆的社会工作者, 有了鹰之怒您可以通过互联网获得海外所有热点事件的资讯, 倾听不同的观点</p>
    <p>如何您是身处中国大陆的普通中国人, 有了鹰之怒您可以通过互联网获得海外所有娱乐视听资源、游戏资源、新闻资讯、影视资源、爱情动作片</p>
    <p>有了鹰之怒您在中国大陆才真正拥有了互联网</p>
    <p class="p-a" style="font-size: 24px;">功能与服务</p>
    <p>鹰之怒是我们团队用4年时间精心为您打造的一款网络访问工具, 核心代码使用c语言编写, 一些辅助工具使用nim语言编写</p>
    <p>鹰之怒目前提供如下三个平台的客户端软件</p>

    <div style="margin-top: 10px;">
      <div tyle="align-items: center;">
        <img
          src="../assets/media/tux.png"
          style="width: 32px; height 32px;"
        >
        <span style="margin-left: 5px;">Linux&nbsp;-&nbsp;鹰之怒可以运行在基于x86_64架构的Fedora 32以上版本、openSUSE Leap 15.5以上版本以及Ubuntu 18.04/Debian 10以上版本的Linux系统之上</span>
      </div>
      <div tyle="align-items: center;">
        <img
          src="../assets/media/openwrt.png"
          style="width: 32px; height 32px;"
        >
        <span style="margin-left: 5px;">Openwrt&nbsp;-&nbsp;鹰之怒可以运行在安装了 OpenWrt 的所有路由器和软路由之上</span>
      </div>
      <div tyle="align-items: center;">
        <img
          src="../assets/media/android.png"
          style="width: 32px; height 32px;"
        >
        <span style="margin-left: 5px;">Android&nbsp;-&nbsp;鹰之怒可以运行在支持解锁bootloader并且是Android GKI 2.0(kernel 5.10+)以上版本的安卓手机或平板之上</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Home',
  }

</script>

<style scoped>
#page{
  margin-left: 110px;
  padding: 0;
  width: 90%;
}

.p-a {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #2185d0!important;
  font-weight: bold;
  font-size: 24px;
}
</style>
