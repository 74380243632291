import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: sessionStorage.getItem('isLogin') || false, // window.sessionStorage.getItem('isLogin')
        token: sessionStorage.getItem('token') || '',
        username: sessionStorage.getItem('username') || ''
    },
    mutations: {
        login: (state, {token, username}) => {
            sessionStorage.setItem("isLogin", true);
            sessionStorage.setItem("token", token);
            sessionStorage.setItem("username", username);
            state.isLogin=true;
            state.token = token;
            state.username = username;
        },
        logout: (state) => {
            sessionStorage.removeItem('isLogin');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('username');
            state.token = '';
            state.isLogin = false;
            state.username = '';
        }
    }
})
