<template>
  <div id="page1">
    <p class="p-a">付款结果</p>
    <div v-if="this.$route.query.payment_result === 'success' && this.$route.query.username == this.$store.state.username">
      <table border="1" style="width: 80%; border: none;">
        <tr>
          <td style="text-align: left; width: 12%; border: none;">商户订单号</td>
          <td style="text-align: left; width: 88%; border: none;">{{ this.$route.query.order_id }}</td>
        </tr>
        <tr>
          <td style="text-align: left; width: 12%; border: none;">所购商品</td>
          <td style="text-align: left; width: 88%; border: none;">{{ this.$route.query.subject }}</td>
        </tr>
        <tr>
          <td style="text-align: left; width: 12%; border: none;">实际支付金额</td>
          <td style="text-align: left; width: 88%; border: none;">{{ this.$route.query.actual_amount }}</td>
        </tr>
        <tr>
          <td style="text-align: left; width: 12%; border: none;">USDT交易哈希</td>
          <td style="text-align: left; width: 88%; border: none;">{{ this.$route.query.block_transaction_id }}</td>
        </tr>
        <tr>
          <td style="text-align: left; width: 12%; border: none;">激活码</td>
          <td style="text-align: left; width: 88%; border: none;"><button class="button-demo" @click="download()">下载</button></td>
        </tr>
      </table>
    </div>
    <div v-if="this.$route.query.payment_result === 'fail' && this.$route.query.username == this.$store.state.username">
      <table border="1" style="width: 80%; border: none;">
        <tr>
          <td style="text-align: left; width: 12%; border: none;">付费出错</td>
          <td style="text-align: left; width: 88%; border: none;">{{ this.$route.query.describe }}</td>
        </tr>
      </table>
    </div>
    <div v-if="msg" class="message">
      {{ msg }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Payment',
  data() {
    return {
      msg: ''
    };
  },
  methods: {
    download() {
      var config = {
        method: 'post',
        url: 'download1',
        data: {
            order_id: this.$route.query.order_id // 商户订单号
        }
      };
      this.$axios(config)
        .then(response => {
          if(response.status == 200) {
            var blob = new Blob([response.data.key], {type: "text/plain;charset=utf-8"});
            var filename = response.data.filename;
            saveAs(blob, filename);
            this.msg = response.data.message;
          }
          if(response.status == 202) {
            this.msg = response.data.message;
          }
        })
        .catch(error => {
          if (error.response) {
              this.msg = '服务器响应错误'; // 服务器响应超时或服务器宕机等原因
          } else if (error.request) {
              this.msg = '请求未发出或未收到服务器响应'; // 例如服务器不允许跨域资源共享(CORS)
          } else {
              this.msg = '发生未知错误';
          }
        });
    }
  }
}
</script>

<style scoped>
#page1{
  margin-left: 110px;
  padding: 0;
  height: 600px;
  width: 90%;
}

.p-a {
  margin-top: 25px;
  margin-bottom: 10px;
  color: #2185d0!important;
  font-weight: bold;
  font-size: 24px;
}

.button-demo {
  display:block;
  width:50px;
  height:24px;
  border-radius:2px;
  line-height: 25px;
  border:none;
  background-color:#1DA1F2;
  color:#fff;
  font-size:14px;
}

.message {
  color: red;
  margin-left: 3px;
}
</style>
