import axios from 'axios'
import store from '../store'
import qs from 'qs';

// 创建axios实例
const http = axios.create({
    baseURL: 'https://hawkfury.com/api/', // api的base_url
    timeout: 10000 // 请求超时时间
});

// 请求拦截
http.interceptors.request.use(
    config => {
        const token = store.state.token;
        if (token) {
            config.headers['Authorization'] = token;
        }

        const username = store.state.username;

        // 请求体中加入客户端登录的用户名
        if (username) {
            if(config.method === 'post') {
                config.data = {
                    ...config.data,
                    username: username
                };

                config.data = qs.stringify(config.data);
            } else if(config.method === 'get') {
                config.params = {
                    ...config.params,
                    username: username
                };
            }
        }
               
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

// 响应拦截 
http.interceptors.response.use(
    response => {
        const res = response.data;

        // code为非2000是抛错 可结合自己业务进行修改
        if (res.code !== 2000) {
            // 5002:非法的token  5003:token过期了 5004:token解析异常 5005:token错误
            if (res.code === 5002 || res.code === 5003 || res.code === 5004 || res.code === 5005) {
                store.commit('logout');
                location.reload(); // 为了重新实例化vue-router对象 避免bug
                router.replace({
                    path: "/login",
                    query: {redirect: router.currentRoute.fullPath} // 登录成功后跳入浏览的当前页面
                });
            }
            return Promise.reject('error'); // return Promise.reject(response);
        } else {
            return response; // return Promise.resolve(response);
        }
    },
    error => {
        console.log(error); // for debug
        return Promise.reject(error);
    }
);

export default http;
