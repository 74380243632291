import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './http'
import './global.css'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueClipboard from 'vue-clipboard2'

import $ from 'jquery'
Vue.prototype.jQuery = jQuery

Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.prototype.$axios = http
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

export default Vue
